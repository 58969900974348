import React, {useState} from 'react';
import styled from 'styled-components';
import KanbanBoard from '../../pages/KanbanBoard/KanbanBoard';

const Project = () => {
  // Hardcoded project data
  const [activeTab, setActiveTab] = useState('fundamentals');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ticketDetails, setTicketDetails] = useState({
    title: '',
    summary: '',
    dueDate: '',
    assignee: '',
  });

  const handleCreateTicket = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTicketDetails({ ...ticketDetails, [name]: value });
  };

  // Function to handle form submission for creating a ticket
  const handleSubmit = (e) => {
    e.preventDefault();
    // Add functionality to submit ticket details
    console.log('Ticket details:', ticketDetails);
    setIsModalOpen(false);
    // You can add logic here to submit ticket details to backend or perform any other actions
  };

  // Function to suggest assignees based on skill and availability
  const suggestAssignee = () => {
    // Add logic to suggest assignees based on skill and availability
    return ['John Doe', 'Jane Smith']; // Hardcoded suggestion for demonstration
  };

  const project = {
    name: 'Fitaic',
    ideation: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed accumsan odio at tellus vulputate vehicula.',
    teams: ['Development Team', 'Design Team'],
    communities: ['Community A', 'Community B'],
    tickets: [
      { id: 1, title: 'Implement feature X', status: 'In Progress' },
      { id: 2, title: 'Fix bug in component Y', status: 'To Do' },
      { id: 3, title: 'Refactor codebase', status: 'Review' },
    ],
    productFit: 'Personal AI Trainer in your fitness journey',
    marketingStrategy: 'Build the MVP and launch beta version.',
    customerAcquisition: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed accumsan odio at tellus vulputate vehicula.',
    valuationAndFinances: {
      investments: 40000,
      revenue: 60000,
      expenses: 30000,
    },
    boardMembersAndEquity: {
      members: [
        { name: 'Mentor 1', equity: 10 },
        { name: 'Mentor 2', equity: 15 },
      ],
    },
    legalAspects: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed accumsan odio at tellus vulputate vehicula.',
  };

  return (
    <Container>
      <ProjectName>{project.name}</ProjectName>
      <Tabs>
        <TabButton onClick={() => setActiveTab('fundamentals')} active={activeTab === 'fundamentals'}>
          Fundamentals
        </TabButton>
        <TabButton onClick={() => setActiveTab('tickets')} active={activeTab === 'tickets'}>
          Tickets
        </TabButton>
      </Tabs>
      {activeTab === 'fundamentals' && (
      <AdditionalInfo>
        <SectionTitle>Objective</SectionTitle>
        <Text>{project.productFit}</Text>
        <SectionTitle>Strategy</SectionTitle>
        <Text>{project.marketingStrategy}</Text>
        <SectionTitle>Valuation and Finances</SectionTitle>
        <Table>
          <tbody>
            <tr>
              <td>Investments</td>
              <td>{project.valuationAndFinances.investments}</td>
            </tr>
            <tr>
              <td>Revenue</td>
              <td>{project.valuationAndFinances.revenue}</td>
            </tr>
            <tr>
              <td>Expenses</td>
              <td>{project.valuationAndFinances.expenses}</td>
            </tr>
          </tbody>
        </Table>
        <SectionTitle>Board Members and Equity</SectionTitle>
        <Table>
          <thead>
            <tr>
              <th>Board Member</th>
              <th>Equity (%)</th>
            </tr>
          </thead>
          <tbody>
            {project.boardMembersAndEquity.members.map((member, index) => (
              <tr key={index}>
                <td>{member.name}</td>
                <td>{member.equity}%</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </AdditionalInfo>
        )}
         {activeTab === 'tickets' && (
          <>
            <SectionTitle>Project Tickets</SectionTitle>
            <CreateTicketButton onClick={handleCreateTicket}>Create Ticket</CreateTicketButton>
            <KanbanBoard />
          </>
        )}
         {isModalOpen && (
        <ModalContainer>
          <Modal>
            <h2>Create Ticket</h2>
            <form onSubmit={handleSubmit}>
              <FormField>
                <Label>Title:</Label>
                <Input type="text" name="title" value={ticketDetails.title} onChange={handleInputChange} />
              </FormField>
              <FormField>
                <Label>Summary:</Label>
                <Input name="summary" value={ticketDetails.summary} onChange={handleInputChange} />
              </FormField>
              <FormField>
                <Label>Due Date:</Label>
                <Input type="date" name="dueDate" value={ticketDetails.dueDate} onChange={handleInputChange} />
              </FormField>
              <FormField>
                <Label>Assignee:</Label>
                <Input type="text" name="assignee" value={ticketDetails.assignee} onChange={handleInputChange} />
                <SuggestionList>
                  {suggestAssignee().map((assignee, index) => (
                    <SuggestionItem key={index}>{assignee}</SuggestionItem>
                  ))}
                </SuggestionList>
              </FormField>
              <ButtonContainer>
                <CancelButton type="button" onClick={handleCloseModal}>Cancel</CancelButton>
                <SubmitButton type="submit">Create</SubmitButton>
              </ButtonContainer>
            </form>
          </Modal>
        </ModalContainer>
      )}
    </Container>
  );
};

export default Project;

const Container = styled.div`
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

const ProjectName = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
`;

const Tabs = styled.div`
  display: flex;
  margin-bottom: 20px;
  width: 700px;
  background-color: transparent; /* Set background color to transparent */
`;

const TabButton = styled.button`
  flex: 1;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  border: none;
  outline: none;
  cursor: pointer;
  color: ${({ active }) => (active ? '#333' : '#555')};
  border-bottom: ${({ active }) => (active ? '2px solid #333' : 'none')};
  transition: color 0.3s ease, border-bottom 0.3s ease;
  background-color: transparent; /* Set background color to transparent */
  &:hover {
    color: ${({ active }) => (active ? '#333' : '#777')};
  }
`;


const SectionTitle = styled.h2`
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #555;
`;

const Ideation = styled.p`
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
  color: #444;
`;

const TeamList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Team = styled.li`
  font-size: 16px;
  margin-bottom: 5px;
  color: #666;
`;

const CommunityList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Community = styled.li`
  font-size: 16px;
  margin-bottom: 5px;
  color: #666;
`;

const TicketList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Ticket = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const TicketTitle = styled.span`
  font-size: 16px;
  flex-grow: 1;
  color: #333;
`;

const TicketStatus = styled.span`
  font-size: 16px;
  color: ${({ status }) => {
    switch (status) {
      case 'To Do':
        return 'red';
      case 'In Progress':
        return 'orange';
      case 'Review':
        return 'blue';
      case 'Done':
        return 'green';
      default:
        return 'black';
    }
  }};
`;

const AdditionalInfo = styled.div`
  margin-top: 40px;
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: #444;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 16px;
  margin-bottom: 20px;
  th,
  td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    text-align: left;
    color: #333;
  }
  th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
`;

const CreateTicketButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 1200px;
  margin-top: -45px;
  position: absolute;
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const Modal = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 40px;
  width: 600px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
`;

const FormField = styled.div`
  margin-bottom: 30px;
`;

const Label = styled.label`
  display: block;
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
`;

const Input = styled.input`
  width: 100%;
  padding: 15px;
  font-size: 16px;
  border: 2px solid #ddd;
  border-radius: 10px;
  transition: border-color 0.3s ease;
  &:focus {
    border-color: #007bff;
  }
`;

const SuggestionList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 5px 0 0;
`;

const SuggestionItem = styled.li`
  margin-top: 10px;
  font-size: 16px;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

const CancelButton = styled.button`
  padding: 15px 30px;
  font-size: 18px;
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #c82333;
  }
`;

const SubmitButton = styled.button`
  padding: 15px 30px;
  font-size: 18px;
  font-weight: bold;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #218838;
  }
  margin-left: 20px;
`;


