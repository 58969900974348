import axios from "axios";

const baseURL =
  process.env.NODE_ENV === "production"
    ? "https://www.crozic.com/api/"
    : "http://localhost:3001/api/";

    const api = axios.create({
        baseURL: baseURL
      });


export default api;