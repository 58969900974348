// Navbar.js
import {React, useState} from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'; // Assuming you have a CSS file for styling



function Navbar({ isAuthenticated }) {
  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to="/" className="logo-link">Crozic</Link>
      </div>
      {/* <div className="navbar-search">
        <input type="text" placeholder="Search" />
        <button className="search-button">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fill="#FFFFFF" d="M21.71 20.29l-4.7-4.7c1.18-1.46 1.89-3.31 1.89-5.3 0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c1.99 0 3.83-.67 5.29-1.79l4.7 4.7c.39.39 1.02.39 1.41 0 .38-.38.39-1.02 0-1.4zM3 11c0-3.31 2.69-6 6-6s6 2.69 6 6-2.69 6-6 6-6-2.69-6-6z"/>
          </svg>
        </button>
      </div> */}
      <ul className="navbar-links">
        {
          isAuthenticated &&  <li><Link to="/dashboard">Dashboard</Link></li>
        }
        <li><Link to="/courses">Courses</Link></li>
        {isAuthenticated ? (
           <li><Link to="/logout">Logout</Link></li> 
        ) : (
          <>
          <li><Link to="/register">Register</Link></li>
          <li><Link to="/login">Log In</Link></li>
        </> 
        )}
       
      </ul>
    </nav>
  );
}

export default Navbar;
