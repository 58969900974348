import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link , useParams} from 'react-router-dom';
import Ticket from '../../components/Ticket/Ticket';
import api from '../../apis/api';

const CourseBoard = () => {
  const initialContent = {
    "ticket_id": 1,
    "title": "Learn React Hooks",
    "description": "Study React Hooks and implement them in a project",
    "learning_video_url": "https://www.youtube.com/embed/cF2lQ_gZeA8",
    "reference_links": "{\"https://reactjs.org/docs/hooks-intro.html\",\"https://github.com/facebook/react\"}",
    "mentor_id": 2,
    "assignee_id": 1,
    "status": "In Progress",
    "time_tracked": {
        "hours": 2
    },
    "due_date": "2024-05-14T18:30:00.000Z",
    "course_id": 1,
    "epic_id": 1,
    "sprint_id": 1,
    "team_id": 1,
    "start_date": "2024-04-14T18:30:00.000Z",
    "sprint_name": "Slayers Sprint 1",
    "sprint_description": "First sprint of the project",
    "sprint_start_date": "2024-04-14T18:30:00.000Z",
    "sprint_end_date": "2024-04-29T18:30:00.000Z"
} ;
  const [courseName, setCourseName] = useState("Web Development");
  const [courseEpics, setCourseEpics] = useState([]);
  const [courseTicketName, setCourseTicketName] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState([]);
  const [learningTickets, setLearningTickets] = useState([]);
  const [selectedEpic, setSelectedEpic] = useState(1);
  const [selectedTicketContent, setSelectedTicketContent] = useState(initialContent);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  // get course id ! 
  const courseId = useParams().id;

  

  useEffect(() => {
    // Fetch course epics

     // Function to fetch data for user team
    

    const fetchCourseEpics = async () => {
      try {
        const response = await api.get(`course-epics/${courseId}`, { withCredentials: true });
        //const data = await response.json();
        setCourseEpics(response.data);
      } catch (error) {
        console.error('Error fetching course epics:', error);
      }
    };

    // const fetchUserProjects = () => {
    //   return api.get(`user-projects/`, { withCredentials: true });
    // };
    // Fetch learning tickets
    const fetchLearningTickets = async () => {
      try {
        // Assuming you have a user ID stored in a variable named userId
        //http://localhost:3001/api/course-learning-tickets/1/1
        const response = await api.get(`course-learning-tickets/${courseId}`,  { withCredentials: true });
       // const data = await response.json();
        setLearningTickets(response.data);
      } catch (error) {
        console.error('Error fetching learning tickets:', error);
      }
    };

    // Call the fetch functions
    fetchCourseEpics();
    fetchLearningTickets();
    // Cleanup function (optional)
  
    return () => {
      // Any cleanup code if needed
    };
  }, [courseId]); 

  // useEffect(() => {
  //   console.log("kya chl rha hai ye ")
  //   if (Object.keys(courseTicketName).length > 0) {
  //     setSelectedEpic(courseTicketName[Object.keys(courseTicketName)[0]]);
  //   }
  // }, [courseTicketName]);

  const getEpicsTicket = () => {
    const ticketMap = {};
  
    learningTickets.forEach(ticket => {
      const { ticket_id, epic_id, title } = ticket;
      if (!ticketMap[epic_id]) {
        ticketMap[epic_id] = [{ id: ticket_id, name: title }];
      } else {
        ticketMap[epic_id].push({ id: ticket_id, name: title });
      }
    });
    setCourseTicketName(ticketMap);
  };

  // const selectedTicketData = learningTickets.find(ticket => ticket.ticket_id === 1);
  // setSelectedTicketContent(selectedTicketData);

  // Call getEpicsTicket() after learningTickets state has been updated
useEffect(() => {
  getEpicsTicket();
}, [learningTickets]);


  const handleEpicClick = (epicId) => {
    // console.log("selected epic id " + epicId);
    // console.log(" course epics are : " + courseEpics)
    // console.log(courseEpics)
    // console.log(learningTickets)
    // console.table(courseTicketName)
    setSelectedEpic(epicId);
    setIsDropdownVisible(!isDropdownVisible);
    // console.log("validating conditions : ")
    // selectedEpic === epic.epic_id && isDropdownVisible && courseTicketName[epic.epic_id]
    // console.log(selectedEpic == epicId);
    // console.log(isDropdownVisible);
    // console.log(courseTicketName[epicId]);
  };

  const handleTicketClick = (ticketId) => {
    const selectedTicketData = learningTickets.find(ticket => ticket.ticket_id === ticketId);
    setSelectedTicketContent(selectedTicketData);
    console.log(selectedTicketData);
  };


  const [selectedTopic, setSelectedTopic] = useState("course Ticket");

  const handleTopicClick = (topicId) => {
    if (selectedTopic === topicId) {
      setSelectedTopic(null);
    } else {
      setSelectedTopic(topicId);
    }
  };

  return (
    <>
      
   
    <Container>
      <LeftBar>
      <CourseName>{courseName}</CourseName>
        {courseEpics.map((epic) => (
          <EpicDropdown key={epic.epic_id}>
            <EpicName onClick={() => handleEpicClick(epic.epic_id)}>{epic.name}</EpicName>
            {selectedEpic === epic.epic_id && isDropdownVisible && courseTicketName[epic.epic_id] && (
              <TicketDropdown>
                {courseTicketName[epic.epic_id].map(ticket => (
                  <TicketName key={ticket.id} onClick={() => handleTicketClick(ticket.id)}>
                    {ticket.name}
                  </TicketName>
                ))}
              </TicketDropdown>
            )}
          </EpicDropdown>
        ))}
        <Link to="/studentBoard">
        <KanbanBoardButton>Go to Kanban Board</KanbanBoardButton>
      </Link>
      <Link to={`/instructorBoard/${courseId}`}>
        <InstructorBoardButton>Instructor Board</InstructorBoardButton>
      </Link>
      </LeftBar>
      {/* <Ticket /> */}
      <Ticket ticketData={selectedTicketContent} />
    </Container>
    </>
  );
};

export default CourseBoard;

const Container = styled.div`
  display: flex;
  padding: 20px;
  height: 650px;
`;


const CourseName = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`;

const LeftBar = styled.div`
  width: 320px;
  overflow-y: auto;
  border-right: 1px solid #ccc;
`;

const Topic = styled.div`
  padding: 10px;
  background-color: ${({ selected }) => (selected ? '#e0e0e0' : 'transparent')};
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const TopicName = styled.div`
  font-weight: bold;
`;

const TopicTime = styled.div`
  color: #666;
`;

const Epic = styled.div`
  margin-top: 10px;
`;

const EpicName = styled.div`
  font-weight: bold;
  color: ${({ color }) => color};
`;

const Tickets = styled.div`
  margin-top: 5px;
`;

const TicketName = styled.div`
  font-weight: bold;
  color: ${({ color }) => color};
`;

const TicketTime = styled.div`
  color: #666;
`;

const KanbanBoardButton = styled.button`
  padding: 10px 20px;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #0056b3;
  }
  margin-top: 400px;
`;

const InstructorBoardButton = styled.button`
  padding: 10px 20px;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #0056b3;
  }
  width: 160px;
  margin-top: 20px;
`;

const EpicDropdown = styled.div`
  margin-bottom: 10px;
`;

const TicketDropdown = styled.div`
  margin-left: 10px;
`;