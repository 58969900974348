import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import api from "../../apis/api"
import './AuthForm.css'; // Import CSS for styling

function RegisterForm() {
const navigate = useNavigate(); 
  const [formData, setFormData] = useState({
    userName: '',
    email: '',
    contactNo: '',
    roleDescription: '',
  });

  const [submitError, setSubmitError] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Assuming you have a backend API endpoint for registering users
      await api.post('waitlist/', formData);
       // Store session ID securely
       //localStorage.setItem('sessionId', response.data.sessionId);
      setSubmitted(true);
    } catch (error) {
      console.error('Error registering user:', error);
      setSubmitError('Failed to submit the form. Please try again or contact us at +91 7849936058');
    }
  };

  return (
    <div className="auth">
      <div className="auth-form">
        <h2>Register</h2>
        {submitError && <p className="error-message">{submitError}</p>}
        {submitted && <p className="success-message">Registration successful! Please check your email for further instructions.</p>}
        <form onSubmit={handleSubmit}>
          <input type="text" name="userName" placeholder="Name" value={formData.userName} onChange={handleChange} required />
          <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
          <input type="text" name="contactNo" placeholder="Contact Number" value={formData.contactNo} onChange={handleChange} required />
          <select name="roleDescription" value={formData.roleDescription} onChange={handleChange} required>
            <option value="">Select Role</option>
            <option value="student">Student</option>
            <option value="instructor">Instructor</option>
            <option value="mentor">Mentor</option>
            <option value="freelancer">Freelancer</option>
          </select>
          <button type="submit">Register</button>
        </form>
        <p>Already have an account? <span onClick={() => navigate('/login')}>Login here</span></p>
        <p>Note: This platform is currently in beta phase. Upon registration, your credentials will be sent to your email.</p>
      </div>
    </div>
  );
}

function LoginForm({ switchToRegister, setUserId ,   setIsAuthenticated}) {
const navigate = useNavigate(); 
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
  });

  const [submitError, setSubmitError] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Assuming you have a backend API endpoint for login
    ///  const response = await axios.post('http://localhost:3001/api/login', loginData);
    const response = await api.post('login/', loginData, {
  withCredentials: true
});

       // Store session ID securely
      //  localStorage.setItem('sessionId', response.data.sessionId);
      // console.log(response.data);
       // Store session ID securely
    // const sessionId = response.headers['set-cookie'].find(cookie => cookie.startsWith('sessionId')).split('=')[1];
    // localStorage.setItem('sessionId', sessionId);
    // Check if 'set-cookie' header exists in the response headers
    // if (response.headers && response.headers['set-cookie']) {
    //   // Store session ID securely
    //   const sessionId = response.headers['set-cookie'].find(cookie => cookie.startsWith('sessionId')).split('=')[1];
    //   localStorage.setItem('sessionId', sessionId);
    // } else {
    //   console.log("error");
    //   console.log(response.data);
    //   console.error('Error logging in: Set-Cookie header not found in response');
    //   setSubmitError('Failed to log in. Please try again or contact support.');
    //   return; // Exit early if 'set-cookie' header is missing
    // }
   // console.log(response)
    if (response.headers && response.headers['set-cookie']) {

      const sessionId = response.headers['set-cookie'].find(cookie => cookie.startsWith('sessionId'));
      if (sessionId) {
        localStorage.setItem('sessionId', sessionId.split('=')[1]);
      } else {
        console.log('Error logging in: sessionId not found in Set-Cookie header');
        setSubmitError('Failed to log in. Please try again or contact support.');
      }
    } else {
      console.log('Error logging in: Set-Cookie header not found in response');
      setSubmitError('Failed to log in. Please try again or contact support.');
    }
   // console.log(response.data);
    setSubmitted(true);
    setIsAuthenticated(true);
      // Assuming the server returns the userId
      navigate('/welcome', { state: { id: response.data.userId} });
    } catch (error) {
      console.error('Error logging in:', error);
      setSubmitError('Failed to submit the form. Please try again or contact us at +91 7742298369');
    }
  };

  return (
    <div className="auth">
      <div className="auth-form">
        <h2>Login</h2>
        {submitError && <p className="error-message">{submitError}</p>}
        {submitted && <p className="success-message">Login successful!</p>}
        <form onSubmit={handleSubmit}>
          <input type="email" name="email" placeholder="Email" value={loginData.email} onChange={handleChange} required />
          <input type="password" name="password" placeholder="Password" value={loginData.password} onChange={handleChange} required />
          <button type="submit">Login</button>
        </form>
        <p>Don't have an account? <span onClick={() => navigate('/register')}>Register here</span></p>
        <p>Note: This platform is currently in beta phase. If you already have credentials, you can login here.</p>
      </div>
    </div>
  );
}

export { RegisterForm, LoginForm };
