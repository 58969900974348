import React from 'react';
import styled from 'styled-components';
import './CommunityBoard.css'

const CommunityBoard = () => {
  // Hardcoded community data
  const community = {
    name: 'Tech Enthusiasts Community',
    members: 1500,
    hierarchy: [
      { role: 'Project Owner', name: 'John Doe' },
      { role: 'Instructor', name: 'Jane Smith' },
      { role: 'Developer', name: 'Alice Johnson' },
    ],
    courses: [
      { id: 1, title: 'Web Development Fundamentals' },
      { id: 2, title: 'Python Programming for Beginners' },
      { id: 3, title: 'Machine Learning Essentials' },
    ],
    projects: [
      { id: 1, name: 'Community Website Redesign' },
      { id: 2, name: 'Tech Conference Planning' },
    ],
    statistics: {
      coursesCompleted: 4,
      projectsCompleted: 2,
      activeTickets: 25,
    },
    recentActivities: [
        { title: 'New Course Added', time: '1 day ago', description: 'Introduction to Data Science' },
        { title: 'Project Update', time: '2 days ago', description: 'Completed backend development' },
      ],
  };

  return (
    <Container>
      <Header>
        <CommunityName>{community.name}</CommunityName>
        <StatsSection>
        <Stat>
          <StatTitle>Members</StatTitle>
          <StatValue>{community.members}</StatValue>
        </Stat>
        <Stat>
          <StatTitle>Courses</StatTitle>
          <StatValue>{community.statistics.coursesCompleted}</StatValue>
        </Stat>
        <Stat>
          <StatTitle>Projects</StatTitle>
          <StatValue>{community.statistics.projectsCompleted}</StatValue>
        </Stat>
        <Stat>
          <StatTitle>Tasks</StatTitle>
          <StatValue>{community.statistics.activeTickets}</StatValue>
        </Stat>
      </StatsSection>
      </Header>
      <TicketSection>
            <div className="ticketHeaders">
                <p className="ticketHeader">Projects</p>
                <p className="ticketHeader">Courses</p>
                <p className="ticketHeader">Teams</p>
            </div>
            <div className="orgtickets">
                <div className="taskTicket">
                    <p className="ticketTitle">Project Crozbow</p>
                    <p className="ticketStatus">15 Members</p>
                    <p className="progressBar">2 New Tasks</p>
                </div>
                <div className="taskTicket2">
                    <p className="ticketTitle">Project Mapnect</p>
                    <p className="ticketStatus">25 Members</p>
                    <p className="progressBar">14 New Tasks</p>
                </div>
                <div className="taskTicket2">
                    <p className="ticketTitle">Project Fitaic</p>
                    <p className="ticketStatus">35 Members</p>
                    <p className="progressBar">18 New Tasks</p>
                </div>
            </div>
      </TicketSection>
      <Sidebar>
        <MembersSection>
          <SidebarTitle>Members</SidebarTitle>
          {community.hierarchy.map(member => (
            <Member key={member.name}>
              <MemberName>{member.name}</MemberName>
              <MemberRole>{member.role}</MemberRole>
            </Member>
          ))}
        </MembersSection>
        <RecentActivitiesSection>
          <SidebarTitle>Recent Activities</SidebarTitle>
          {community.recentActivities.map(activity => (
            <ActivityCard key={activity.title}>
              <ActivityTitle>{activity.title}</ActivityTitle>
              <ActivityTime>{activity.time}</ActivityTime>
              <ActivityDescription>{activity.description}</ActivityDescription>
            </ActivityCard>
          ))}
        </RecentActivitiesSection>
        </Sidebar>
    </Container>
  );
};

export default CommunityBoard;

const Container = styled.div`
  padding: 20px;
  height: 650px;
`;

const Header = styled.div`
  text-align: start;
  background: linear-gradient(to right, #CFDEF3, #E0EAFC);
  width: 850px;
  height: 200px;
  border-radius: 30px;
`;

const CommunityName = styled.h1`
  font-size: 24px;
  font-weight: bold;
  padding-top: 20px;
  margin-left: 50px;
  color: #333;
`;

const TicketSection = styled.div`
    margin-top: 10px;
`;

const StatsSection = styled.div`
    display: flex;
    justify-content: flex-start;
    padding: 20px;
`;

const Stat = styled.div`
    margin-left: 50px;
`;

const StatTitle = styled.h4`
`;

const StatValue = styled.h4`
    margin-left: 10px;
`;

const TicketsSection = styled.div``;

const Sidebar = styled.div`
  width: 400px;
  margin-left: 950px;
  margin-top: -600px;
`;

const MembersSection = styled.div`
  background-color: #f7f7f7;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 20px;
`;

const RecentActivitiesSection = styled.div`
  background-color: #f7f7f7;
  border-radius: 20px;
  padding: 20px;
  margin-top: 10px;
`;

const SidebarTitle = styled.h2`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Member = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
`;

const MemberName = styled.p`
  font-weight: bold;
`;

const MemberRole = styled.p`
  color: #666;
  margin-left: -10px;
`;

const ActivityCard = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ActivityTitle = styled.p`
  font-weight: bold;
  margin-bottom: 5px;
`;

const ActivityTime = styled.p`
  color: #666;
  margin-bottom: 5px;
`;

const ActivityDescription = styled.p`
  color: #333;
`;