import React, {useState, useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Dashboard.css';
import assistant1 from '../../assets/images/assistant1.jpeg'
import api from '../../apis/api';
// dashboard page2
const Dashboard = ({isAuthenticated}) => {
  // const {state} = useLocation();
  // const id = 1;
  // if (state != null) {
  //   const {id} = state;
  // }
  // console.log(id);

  const [userCommunities, setUserCommunities] = useState([]);
  const [userProjects, setUserProjects] = useState([]);
  const [userCourses, setUserCourses] = useState([]);
  const [userCourseTickets, setUserCourseTickets] = useState([]);
  const [userProjectTickets, setUserProjectTickets] = useState([]);
  const [userTickets, setUserTickets] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("In Progress");

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
  };

  useEffect(() => {
    // Function to fetch data for user course
    // const fetchUserCourses = () => {
    //   return fetch(`http://localhost:3001/api/user-courses/${id}`).then(response => response.json());
    // };
    // const fetchUserCourse = () => {
    //   return api.get(`user-courses/`, { withCredentials: true });
    // };
    const fetchUserCourses = () => {
      return api.get(`user-courses/`, { withCredentials: true });
    };

    // Function to fetch data for user team
    const fetchUserProjects = () => {
      return api.get(`user-projects/`, { withCredentials: true });
    };

    // Function to fetch data for user community
    const fetchUserCommunities = () => {
      return api.get(`user-communities/`, { withCredentials: true });
    };

    // Function to fetch data for user tickets
    const fetchUserCourseTickets = () => {
      return api.get(`learning-tickets/`, { withCredentials: true });
    };

    const fetchUserProjectTickets = () => {
      return api.get(`project-tickets/`, { withCredentials: true });
    };

    // Call all the fetch functions simultaneously
    Promise.all([fetchUserCourses(), fetchUserProjects(), fetchUserCommunities(), fetchUserCourseTickets() , fetchUserProjectTickets()])
      .then(([courseData, projectData, communityData, ticketsData, projectTickets]) => {
        setUserCourses(courseData.data);
        setUserProjects(projectData.data);
        setUserCommunities(communityData.data);
        setUserCourseTickets(ticketsData.data);
        setUserProjectTickets(projectTickets.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const combinedTickets = [...userProjectTickets, ...userCourseTickets];

  // Set the state of userTickets with the combined array
  useEffect(() => {
    setUserTickets(combinedTickets);
  }, [userProjectTickets, userCourseTickets]);

  return (
    <div className="dashboard">
      
      <div className="courses">
        <h2 className="communityTitle">Joined Communities</h2>
        <div className="course-cards">
        {userCommunities.map((community, index) => (
          <Link to={`/communityBoard/${community.id}`} className="Link" key={index}>
            <div className="course-detail">
              <h3 className="courseName">{community.name}</h3>
            </div>
          </Link>
        ))}
        </div>
        <h2 className="courseTitle">Ongoing Courses</h2>
        <div className="course-cards">
        {userCourses.map((course, index) => (
          <Link to={`/instructorBoard/${course.id}`} className="Link" key={index}>
            <div className="course-detail">
              <h3 className="courseName">{course.course_name}</h3>
            </div>
          </Link>
        ))}
        </div>
        <h2 className="courseTitle">Current Projects</h2>
        <div className="course-cards">
        {userProjects.map((project, index) => (
          <Link to={`/projectBoard/${project.project_id}`} className="Link" key={index}>
            <div className="course-detail">
              <h3 className="courseName">{project.name}</h3>
            </div>
          </Link>
        ))}
        </div>
      </div>
      <div className="assistant">
        <img src={assistant1} alt="assistant" />
        <div className="assistantText">
            <p className="assistantText1">Hii Chirag !</p>
            <p className="assistantText2"> You got 2 modules to complete today. Let's start with first task !</p>
        </div>
        <div className="assistantTask">
            <div className="taskCard">
                <p className="taskName">Start Course Fundamentals</p>
            </div>
        </div>
      
      </div>
      <div className="ticketSection">
      <div className="ticketHeaders">
        <p className="ticketHeader" onClick={() => handleStatusChange("In Progress")}>In Progress</p>
        <p className="ticketHeader" onClick={() => handleStatusChange("To Do")}>To Do</p>
        <p className="ticketHeader" onClick={() => handleStatusChange("Others")}>Others</p>
      </div>
      <div className="tickets">
        {userTickets
          .filter(ticket => {
            if (selectedStatus === "Others") {
              return ticket.status !== "In Progress" && ticket.status !== "To Do";
            } else {
              return ticket.status === selectedStatus;
            }
          })
          .map((ticket, index) => (
            <Link to={`/courseBoard/1`} className="Link" key={index}>
              <div className="ticketone">
                <p className="ticketTitle">{ticket.title}</p>
                <p className="ticketStatus">{ticket.status}</p>
                <p className="progressBar">{ticket.dueDate}</p>
              </div>
            </Link>
          ))}
      </div>
    </div>
    </div>
  );
}

export default Dashboard;
