import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './CoursePage.css';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import CourseDetailPage from '../../components/Courses/CourseDetailPage'; // Adjust import based on your folder structure

gsap.registerPlugin(ScrollTrigger);

function CoursesPage() {
  const headerRef = useRef(null);
  const coursesRef = useRef([]);

  const courses = [
    { id: 1, title: "Web Development", instructor: "John Doe", description: "Learn the basics of web development including HTML, CSS, and JavaScript.", image: "webdev.jpeg" },
    { id: 2, title: "Blockchain", instructor: "Jane Smith", description: "Start your journey into app development with this beginner-friendly course. Learn to build mobile applications from scratch.", image: "appdev.jpeg" },
    { id: 3, title: "Artificial Intelligence", instructor: "Alex Johnson", description: "Dive into the world of AI and machine learning. Understand fundamental concepts and apply them to real-world problems.", image: "learnai.jpeg" }
  ];

  useEffect(() => {
    // Parallax effect for header
    gsap.fromTo(headerRef.current, 
      { y: 100, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 1.5,
        ease: 'power3.out',
        scrollTrigger: {
          trigger: headerRef.current,
          start: 'top 80%',
          end: 'bottom 60%',
          scrub: 1,
        }
      }
    );

    // Animations for course cards
    coursesRef.current.forEach((course, index) => {
      gsap.fromTo(course, 
        { opacity: 0, y: 50, scale: 0.8 },
        {
          opacity: 1,
          y: 0,
          scale: 1,
          duration: 1.5,
          ease: 'power3.out',
          scrollTrigger: {
            trigger: course,
            start: 'top 80%',
            end: 'bottom 60%',
            scrub: 1,
            delay: index * 0.3, // Stagger animations
          }
        }
      );
    });
  }, []);

  return (
    <div className="courses-container">
      <div ref={headerRef} className="courses-header">
        <h1 className="courses-title">Featured Roadmaps by Communities</h1>
        <p className="courses-description">Explore a wide range of courses taught by industry experts.</p>
      </div>
      <div className="courses-list">
        {courses.map((course, index) => (
          <Link key={course.id} to={`/courses/${course.id}`} className="course-link">
            <div ref={el => coursesRef.current[index] = el} className="course-card-imp">
              <img src={require(`../../assets/images/${course.image}`)} alt={course.title} className="course-image" />
              <div className="course-info">
                <h3 className="course-title">{course.title}</h3>
                <p className="course-instructor">Instructor: {course.instructor}</p>
                <p className="course-description">{course.description}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default CoursesPage;
