// CommunityDetailPage.js
import React from 'react';
import './CommunityDetailPage.css'; // Assuming you have a CSS file for styling

function CommunityDetailPage() {
  // Hardcoded data for demonstration
  const communities = [
    { id: 1, name: "Artificial Intelligence", description: "A community of expert developers building innovative software solutions.", image: "ai.jpeg" },
    { id: 2, name: "Frontend Development", description: "Explore the world of design with industry-leading designers and creatives.", image: "backend.jpeg" },
    { id: 4, name: "Marketing Minds", description: "Learn the latest marketing strategies from seasoned marketers and industry professionals.", image: "marketing.webp" },
    { id: 5, name: "App Developers", description: "Join a vibrant startup community and learn how to launch and grow successful businesses.", image: "app.jpeg" }
  ];

  return (
    <div className="community-detail-page">
      <div className="community-detail-header">
        <h1 className="community-detail-title">Build Products with Communities</h1>
        <p className="community-detail-description">Join vibrant communities, learn from experts, and collaborate on innovative projects to build products that make an impact.</p>
      </div>
      <div className="community-slider">
        <h2 className="community-slider-title">Featured Communities</h2>
        <div className="community-slider-wrapper">
          {communities.map(community => (
            <div key={community.id} className="community-card">
              <img src={require(`../../assets/images/${community.image}`)} alt={community.name} className="community-image" />
              <div className="community-info">
                <h3 className="community-name">{community.name}</h3>
                <p className="community-description">{community.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CommunityDetailPage;
