import React from 'react';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const KanbanBoard = () => {
  // Hardcoded data for tasks with more details
  const tasks = [
    {
      id: 'task1',
      title: 'Task 1: Build Landing Page',
      description: 'Design and develop a landing page for the new product launch.',
      status: 'To Do',
      epic: 'React Introduction',
      dueDate: '2024-02-20',
      assignedTo: 'John Doe',
      storyPoints: 3,
    },
    {
      id: 'task2',
      title: 'Task 2: Implement Login Functionality',
      description: 'Create login and authentication functionality for the application.',
      status: 'In Progress',
      epic: 'User Authentication',
      dueDate: '2024-02-22',
      assignedTo: 'Jane Smith',
      storyPoints: 5,
    },
    {
      id: 'task3',
      title: 'Task 3: Review Wireframes',
      description: 'Review and provide feedback on the wireframes for the new feature.',
      status: 'Review',
      epic: 'Javascript',
      dueDate: '2024-02-25',
      assignedTo: 'Alice Johnson',
      storyPoints: 2,
    },
    {
      id: 'task4',
      title: 'Task 4: Bug Fixes',
      description: 'Address and fix reported bugs in the application.',
      status: 'Done',
      epic: 'Node JS',
      dueDate: '2024-02-18',
      assignedTo: 'Michael Brown',
      storyPoints: 1,
    },
    // Add more tasks as needed
  ];

  // Group tasks by status
  const statuses = ['To Do', 'In Progress', 'Review', 'Done'];

  // Group tasks by epics
  const epics = Array.from(new Set(tasks.map((task) => task.epic)));

  // Function to handle task reordering
  const handleDragEnd = () => {
    // Add logic to handle task reordering
  };

  return (
    <Container>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Board>
          {statuses.map((status) => (
            <Column key={status}>
              <ColumnTitle>{status}</ColumnTitle>
              <Droppable droppableId={status} key={status}>
                {(provided) => (
                  <TaskList {...provided.droppableProps} ref={provided.innerRef}>
                    {epics.map((epic) => (
                      <EpicSection key={epic}>
                        <EpicTitle>{epic}</EpicTitle>
                        {tasks
                          .filter((task) => task.status === status && task.epic === epic)
                          .map((task, index) => (
                            <Draggable key={task.id} draggableId={task.id} index={index}>
                              {(provided) => (
                                <TaskContainer
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <TaskTitle>{task.title}</TaskTitle>
                                  <TaskDescription>{task.description}</TaskDescription>
                                  <TaskDetails>
                                    <DetailItem>Due Date: {task.dueDate}</DetailItem>
                                    <DetailItem>Story Points: {task.storyPoints}</DetailItem>
                                  </TaskDetails>
                                </TaskContainer>
                              )}
                            </Draggable>
                          ))}
                      </EpicSection>
                    ))}
                    {provided.placeholder}
                  </TaskList>
                )}
              </Droppable>
            </Column>
          ))}
        </Board>
      </DragDropContext>
    </Container>
  );
};

// Styled Components
const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const Board = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Column = styled.div`
  flex: 1;
  background-color: #f4f4f4;
  border-radius: 8px;
  padding: 20px;
  margin-right: 20px;
`;

const ColumnTitle = styled.h2`
  font-size: 20px;
  color: #333;
  margin-bottom: 15px;
`;

const TaskList = styled.div`
  min-height: 100px;
`;

const EpicSection = styled.div`
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
`;

const EpicTitle = styled.h3`
  font-size: 18px;
  color: #555;
  margin-bottom: 10px;
`;

const TaskContainer = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
`;

const TaskTitle = styled.h4`
  font-size: 16px;
  color: #333;
  margin-bottom: 8px;
`;

const TaskDescription = styled.p`
  font-size: 14px;
  color: #666;
  margin-bottom: 8px;
`;

const TaskDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const DetailItem = styled.div`
  margin-right: 15px;
`;

export default KanbanBoard;