// Home.js
import React, { useEffect, useRef } from 'react';
import './Home.css'; // Assuming you have a CSS file for styling
import CommunityDetailPage from '../Community/CommunityDetailPage';
import CoursesPage from '../CoursesPage/CoursesPage';
import Features from '../Features/Features';
import PartnersPage from '../Partners/PartnersPage';
import Footer from '../Footer/Footer';
import { gsap } from 'gsap';

function Home() {
  const titleRef = useRef(null);
  const titles = ["Learn With Communities", "Build With Brands", "Earn from your Skills"];
  let titleIndex = 0;

  useEffect(() => {
    const titleElement = titleRef.current;

    const changeTitle = () => {
      gsap.to(titleElement, {
        opacity: 0,
        duration: 1,
        onComplete: () => {
          titleIndex = (titleIndex + 1) % titles.length;
          titleElement.textContent = titles[titleIndex];
          gsap.to(titleElement, { opacity: 1, duration: 1 });
        }
      });
    };

    const interval = setInterval(changeTitle, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
    <div className="home-container">
      <div className="home-content">
      <h1 ref={titleRef} className="home-title">Learn With Communities</h1>
        <p className="home-description">Empower yourself with us to learn, earn, and build at the same place</p>
        <button className="home-action-button">Get Started for free</button>
      </div>
    </div>
    <Features />
    {/* <CommunityDetailPage /> */}
    <CoursesPage />
    <PartnersPage />
    <Footer />
    </div>
  );
}

export default Home;
