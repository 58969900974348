import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './PartnersPage.css';

gsap.registerPlugin(ScrollTrigger);

function PartnersPage() {
  const partnersRef = useRef(null);
  const communityRef = useRef(null);
  const featuresRef = useRef([]);
  const floatingShapesRef = useRef([]);

  const partners = [
    "Techfine",
    "Mapnect",
    "Crozbo",
    "Fitaic",
    "Blockhost",
    "TaskDone",
    "Stepcare",
    "Crozton",
  ];

  const shapes = [
    "Open Source Contributions",
    "Real-World Learning",
    "Skill Development",
    "Collaborative Projects",
    "Earn While You Learn",
    "Professional Networking"
  ];

  const partnersList = partners.concat(partners);
  useEffect(() => {
    const container = partnersRef.current;

    const partnerWidth = container.children[0].offsetWidth;
    const totalWidth = partnerWidth * partners.length;

    gsap.to(container, {
      x: `-${totalWidth}px`,
      duration: partners.length * 2,
      ease: 'linear',
      repeat: -1,
    });

    gsap.fromTo(communityRef.current, {
      opacity: 0,
      y: 50
    }, {
      opacity: 1,
      y: 0,
      duration: 1,
      scrollTrigger: {
        trigger: communityRef.current,
        start: 'top center',
        toggleActions: 'play none none reverse'
      }
    });

    featuresRef.current.forEach((feature, index) => {
      gsap.fromTo(feature, {
        opacity: 0,
        y: 50
      }, {
        opacity: 1,
        y: 0,
        duration: 1,
        delay: index * 0.2,
        scrollTrigger: {
          trigger: feature,
          start: 'top 80%',
          toggleActions: 'play none none reverse'
        }
      });
    });

    floatingShapesRef.current.forEach((shape, index) => {
      gsap.to(shape, {
        y: '+=50',
        x: '+=30',
        repeat: -1,
        yoyo: true,
        duration: 5 + index,
        ease: 'power1.inOut',
      });
      gsap.to(shape, {
        rotation: 360,
        duration: 20 + index,
        repeat: -1,
        ease: 'linear',
      });
    });
  }, [partners]);

  return (
    <div className="partners-page-container">
      <div className="partners-section">
        <h2 className="partners-title">Our Partners</h2>
        <div className="partners-list">
          <div ref={partnersRef} className="partners-scroll">
            {partners.concat(partners).map((partner, index) => (
              <React.Fragment key={index}>
                <div className="partner-name">{partner}</div>
                {index < partners.length * 2 - 1 && <div className="vertical-line"></div>}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>

      <div ref={communityRef} className="community-box">
        <h2 className="community-title">Welcome to Techfine Community</h2>
        <p className="community-description">
          Techfine is a collaborative community that focuses on open-source projects and brand collaborations. We provide a structured roadmap for learners to acquire new skills, build work experience, earn a paycheck, and work on real projects or create their own for the real world. Our community emphasizes collaboration and engagement, ensuring that every member grows and succeeds.
        </p>
        <div className="community-features">
          <div className="feature" ref={el => featuresRef.current[0] = el}>
            <h3>Collaborate on Open Source Projects</h3>
            <p>Work with others on exciting open-source projects that impact the world.</p>
          </div>
          <div className="feature" ref={el => featuresRef.current[1] = el}>
            <h3>Structured Learning Roadmap</h3>
            <p>Follow a well-defined path to acquire new skills and enhance your career.</p>
          </div>
          <div className="feature" ref={el => featuresRef.current[2] = el}>
            <h3>Earn While You Learn</h3>
            <p>Get paid as you contribute to projects and grow your professional network.</p>
          </div>
        </div>
        <div className="floating-shapes">
          {shapes.map((shape, index) => (
            <div
              key={index}
              className={`floating-shape shape-${index + 1}`}
              ref={(el) => (floatingShapesRef.current[index] = el)}
            >
              {shape}
            </div>
          ))}
        </div>
      </div>

      <div className="cta-section">
        <p className="cta-motivational">Join us today and start your journey towards success!</p>
        <button className="cta-button">Get Started for Free</button>
      </div>
    </div>
  );
}

export default PartnersPage;
