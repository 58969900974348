import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { useLocation,  useNavigate } from 'react-router-dom';
import assistant1 from '../../assets/images/assistant1.jpeg';
import api from '../../apis/api';

// Styled Components
const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #ffffff;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
  margin-top: -40px;
`;

const Description = styled.p`
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 20px;
  text-align: center;
`;

const RoadmapContainer = styled.div`
  margin-bottom: 20px;
  margin-left: -730px;
`;

const RoadmapTitle = styled.h2`
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 10px;
`;

const RoadmapStep = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const StepNumber = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #007bff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 10px;
`;

const StepDescription = styled.p`
  font-size: 1.2rem;
  color: #666;
`;

const VideoContainer = styled.div`
  margin-bottom: 40px;
  margin-left: 740px;
  margin-top: -370px;
`;

const YouTubeVideo = styled.iframe`
  width: 560px;
  height: 315px;
`;

const DashboardButton = styled.button`
  padding: 15px 30px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #0056b3;
  }
`;

const Assistant = styled.div`
  margin-top: -200px;
  margin-left: -1200px;
`

const Welcome = () => {
  // const {state} = useLocation();
  // const id = 1;
  // if (state != null) {
  //   const {id} = state;
  // }
  const [userCourse, setUserCourse] = useState("");
  const [userTeam, setUserTeam] = useState("");
  const [userCommunity, setUserCommunity] = useState("");
  const [userTickets, setUserTickets] = useState(0);
  const navigate = useNavigate(); 
  useEffect(() => {
    // Function to fetch data for user course
    const fetchUserCourse = () => {
      return api.get(`user-courses/`, { withCredentials: true });
    };

    // Function to fetch data for user team
    const fetchUserTeam = () => {
      return api.get(`get-user-teams/`, { withCredentials: true });
    };

    // Function to fetch data for user community
    const fetchUserCommunity = () => {
      return api.get(`user-communities/`,  { withCredentials: true });
    };

    // Function to fetch data for user tickets
    const fetchUserTickets = () => {
      return api.get(`user-ticket-count/`,  { withCredentials: true });
    };

    // Call all the fetch functions simultaneously
    Promise.all([fetchUserCourse(), fetchUserTeam(), fetchUserCommunity(), fetchUserTickets()])
      .then(([courseData, teamData, communityData, ticketsData]) => {
        setUserCourse(courseData.data[0].course_name);
        setUserTeam(teamData.data[0].team_name);
        setUserCommunity(communityData.data[0].name);
        setUserTickets(ticketsData.data.ticket_count);
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          alert('Your aur not authenticated or your session is invalid. Please log in again.');
          navigate('/login'); 
         // Redirect to login page
        } 
        console.error('Error fetching data:', error);
      });
  }, []); // Dependency array to run effect only when 'id' changes


  return (
    <WelcomeContainer>
      <Title>Welcome and Congratulations on enrolling in Crozic !</Title>
      <Description>
      We're excited to have you join us and look forward to supporting your learning journey!! Below, you'll find orientation you need
        to succeed in your course.
      </Description>
      <RoadmapContainer>
        <RoadmapTitle>Roadmap:</RoadmapTitle>
        <RoadmapStep>
          <StepNumber>1</StepNumber>
          <StepDescription>You're now enrolled in course {userCourse}!</StepDescription>
        </RoadmapStep>
        <RoadmapStep>
          <StepNumber>2</StepNumber>
          <StepDescription>You'll join {userCommunity} community to connect with peers and work on projects.</StepDescription>
        </RoadmapStep>
        <RoadmapStep>
          <StepNumber>3</StepNumber>
          <StepDescription>Team {userTeam} awaits you for mentorship and collaboration.</StepDescription>
        </RoadmapStep>
        <RoadmapStep>
          <StepNumber>4</StepNumber>
          <StepDescription>{userTickets} tickets assigned. Dive into tasks and learning.</StepDescription>
        </RoadmapStep>
        <RoadmapStep>
          <StepNumber>5</StepNumber>
          <StepDescription>Watch our video guide, then head to your dashboard to start learning!</StepDescription>
        </RoadmapStep>
        {/* Add more steps as necessary */}
      </RoadmapContainer>
      <VideoContainer>
        <YouTubeVideo
          src="https://www.youtube.com/embed/08D0dBGIzYQ" // Placeholder link
          title="How to use our platform"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></YouTubeVideo>
      </VideoContainer>
      <DashboardButton onClick={() => navigate('/dashboard')}>
        Go to Dashboard
      </DashboardButton>
    </WelcomeContainer>
  );
};

export default Welcome;
