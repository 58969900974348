import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import './InstructorBoard.css'

const InstructorBoard = () => {
  // Hardcoded instructor data
  const instructor = {
    courseName: 'Web Development Fundamentals',
    studentsEnrolled: 120,
    ticketsCompleted: 85,
    topicsCoverage: [
      { topic: 'React Fundamentals', completed: 20 },
      { topic: 'React Hooks', completed: 10 },
      { topic: 'React Projects', completed: 10 },
    ],
    mentors: [
      { name: 'John Doe', progress: '70%' },
      { name: 'Jane Smith', progress: '90%' },
    ],
    statistics: {
      totalStudents: 120,
      averageCompletionRate: '75%',
      mostCompletedTicket: 'HTML Basics - HTML Tags',
    },
    topicsInProgress: [
        { topic: 'JavaScript Essentials', completed: 60 },
      ],
      topicsInValidation: [
        { topic: 'CSS Fundamentals', completed: 100 },
      ],
  };

  return (
    <div>
         <div className="courses">
        <h2 className="communityTitle">Course Projects</h2>
        <div className="course-cards">
        <Link to="/communityBoard" className="Link">
          <div className="course-detail">
            <h3 className="courseName">Fitaic</h3>
          </div>
        </Link>
        </div>
        <div className="course-cards">
        <Link to="/communityBoard" className="Link">
          <div className="course-detail">
            <h3 className="courseName">Crozbow</h3>
          </div>
        </Link>
        </div>
        <h2 className="courseTitle">Course Teams</h2>
        <div className="course-cards">
        <Link to="/courseboard" className="Link">
          <div className="course-detail">
            <h3 className="courseName">Team 1 </h3>
          </div>
        </Link>
        </div>
        <div className="course-cards">
        <Link to="/courseboard" className="Link">
          <div className="course-detail">
            <h3 className="courseName">Team 2</h3>
          </div>
        </Link>
        </div>
        <div className="course-cards">
        <Link to="/courseboard" className="Link">
          <div className="course-detail">
            <h3 className="courseName">Team 3</h3>
          </div>
        </Link>
        </div>
      </div>
    <Container>
      <Header>
        <CourseName>{instructor.courseName}</CourseName>
      </Header>
      <StatsSection>
        <Stat>
          <StatTitle>Students Enrolled</StatTitle>
          <StatValue>{instructor.studentsEnrolled}</StatValue>
        </Stat>
        <Stat>
          <StatTitle>Tickets Completed</StatTitle>
          <StatValue>{instructor.ticketsCompleted}</StatValue>
        </Stat>
        <Stat>
          <StatTitle>Average Completion Rate</StatTitle>
          <StatValue>{instructor.statistics.averageCompletionRate}</StatValue>
        </Stat>
      </StatsSection>
      <KanbanContainer>
      <KanbanColumn>
        <ColumnTitle>To Do</ColumnTitle>
        {instructor.topicsCoverage.map((topic, index) => (
          <Task key={index} completed={topic.completed < 100}>
            <TaskName>{topic.topic}</TaskName>
            <TaskProgress>{topic.completed}%</TaskProgress>
          </Task>
        ))}
      </KanbanColumn>
      <KanbanColumn>
        <ColumnTitle>In Progress</ColumnTitle>
        {/* Tasks in progress can be added here */}
        {instructor.topicsInProgress.map((topic, index) => (
          <Task key={index} completed={topic.completed < 100}>
            <TaskName>{topic.topic}</TaskName>
            <TaskProgress>{topic.completed}%</TaskProgress>
          </Task>
        ))}
      </KanbanColumn>
      <KanbanColumn>
        <ColumnTitle>In Validation</ColumnTitle>
        {/* Tasks in validation can be added here */}
        {instructor.topicsInValidation.map((topic, index) => (
          <Task key={index} completed={topic.completed < 100}>
            <TaskName>{topic.topic}</TaskName>
            <TaskProgress>{topic.completed}%</TaskProgress>
          </Task>
        ))}
      </KanbanColumn>
      <KanbanColumn>
        <ColumnTitle>Completed</ColumnTitle>
        {/* Completed tasks can be added here */}
      </KanbanColumn>
    </KanbanContainer>
    </Container>
    </div>
  );
};

export default InstructorBoard;

const Container = styled.div`
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  height: 650px;
  width: 75%;
`;

const Header = styled.div`
  text-align: start;
`;

const CourseName = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
`;

const StatsSection = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 40px;
  margin-left: -50px;
`;

const Stat = styled.div`
  text-align: center;
  margin-left: -80px;
`;

const StatTitle = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: #555;
`;

const StatValue = styled.p`
  font-size: 24px;
  font-weight: bold;
  color: #333;
`;

const TopicsCoverage = styled.div`
  text-align: center;
`;

const TopicsTitle = styled.h2`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #555;
`;

const TopicList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Topic = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const TopicName = styled.span`
  font-size: 16px;
  color: #333;
`;

const TopicProgress = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #007bff;
`;

const MentorsSection = styled.div`
  text-align: center;
`;

const MentorsTitle = styled.h2`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #555;
`;

const MentorList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Mentor = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const MentorName = styled.span`
  font-size: 16px;
  color: #333;
`;

const MentorProgress = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #007bff;
`;

const KanbanContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #f7f7f7;
`;

const KanbanColumn = styled.div`
  flex: 1;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ColumnTitle = styled.h2`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
`;

const Task = styled.div`
  padding: 10px;
  background-color: ${({ completed }) => (completed ? '#e6f7ff' : '#fff')};
  border: 1px solid ${({ completed }) => (completed ? '#1890ff' : '#ddd')};
  border-radius: 4px;
  margin-bottom: 10px;
  transition: background-color 0.3s, border-color 0.3s;

  &:hover {
    background-color: ${({ completed }) => (completed ? '#d6eaff' : '#f5f5f5')};
  }
`;

const TaskName = styled.p`
  font-size: 16px;
  margin-bottom: 5px;
`;

const TaskProgress = styled.span`
  font-size: 14px;
  color: ${({ completed }) => (completed ? '#1890ff' : '#666')};
`;

