import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './Features.css';

gsap.registerPlugin(ScrollTrigger);

function Features() {
  const feature1Ref = useRef(null);
  const feature2Ref = useRef(null);
  const feature3Ref = useRef(null);

  useEffect(() => {
    const sections = [feature1Ref.current, feature2Ref.current, feature3Ref.current];

    sections.forEach((section) => {
      gsap.fromTo(section, 
        { opacity: 0, y: 100 }, 
        {
          opacity: 1,
          y: 0,
          duration: 1,
          scrollTrigger: {
            trigger: section,
            start: "top 80%",
            end: "bottom 20%",
            scrub: 1,
          }
        }
      );
    });
  }, []);

  return (
    <div className="features-container">
      <div ref={feature1Ref} className="feature">
        <h2>Courses and Roadmaps with Communities</h2>
        <p className="feature-description">Our platform offers a variety of courses and roadmaps designed to help you master new skills. Join communities of like-minded learners to stay motivated and on track.</p>
        <p className="solution">Network with professionals, get your doubts resolved, and collaborate with peers. Our structured courses ensure you stay motivated and achieve your learning goals.</p>
      </div>
      <div ref={feature2Ref} className="feature">
        <h2>Collaboration with Brands</h2>
        <p className="feature-description">Work on projects with leading brands and gain hands-on experience. Collaborate with professionals and stay updated with emerging technologies.</p>
        <p className="solution">Access real-world projects, learn from industry experts, and build a professional portfolio. Our platform bridges the gap between learning and practical application.</p>
      </div>
      <div ref={feature3Ref} className="feature">
        <h2>Earn from your Skills</h2>
        <p className="feature-description">Don't just learn, start earning while you learn. Participate in real projects, apply for jobs or internships, and find freelance opportunities through our platform.</p>
        <p className="solution">Gain experience, build your portfolio, and connect with hiring brands. Our structured approach helps you transition smoothly from learning to earning.</p>
      </div>
    </div>
  );
}

export default Features;
