import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="footer-section about">
          <h2>About Us</h2>
          <p>
            We are a platform dedicated to empowering individuals through community-driven learning and collaboration. Join us to enhance your skills, work on real projects, and network with industry professionals.
          </p>
        </div>
        <div className="footer-section links">
          <h2>Quick Links</h2>
          <ul>
            <li><a href="/contact">Contact</a></li>
            <li><a href="/privacy-policy">Privacy Policy</a></li>
          </ul>
        </div>
        <div className="footer-section contact">
          <h2>Contact Us</h2>
          <ul>
            <li>Email: info@crozic.com</li>
            <li>Phone: +91 7849936058</li>
          </ul>
        </div>
        <div className="footer-section social">
          <h2>Follow Us</h2>
          <div className="social-icons">
            <a href="https://twitter.com" className="social-icon">Twitter</a>
            <a href="https://linkedin.com" className="social-icon">LinkedIn</a>
            <a href="https://instagram.com" className="social-icon">Instagram</a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Crozic. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
