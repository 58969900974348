import React, { useState, useEffect } from 'react';
import Navbar from './components/navbar/Navbar';
import Home from './pages/Home/Home';
import CommunityDetailPage from './pages/Community/CommunityDetailPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CoursesPage from './pages/CoursesPage/CoursesPage';
import ProjectsPage from './pages/Projects/ProjectsPage';
import CourseDetailPage from './components/Courses/CourseDetailPage';
import Dashboard from './pages/Dashboard/Dashboard';
import CourseBoard from './pages/CourseBoard/CourseBoard';
import Sidebar from './components/SideBar/SideBar';
import KanbanBoard from './pages/KanbanBoard/KanbanBoard';
import Project from './components/Project/Project';
import InstructorBoard from './components/Instructor/InstructorBoard';
import CommunityBoard from './components/Community/CommunityBoard';
import Welcome from './pages/Welcome/Welcome';
import { RegisterForm, LoginForm } from './pages/RegisterForm/RegisterForm'
import axios from 'axios';
import api from './apis/api';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // Authentication Logic
  useEffect(() => {
    const fetchAuthenticationStatus = async () => {
      try {
        const response = await api.get('authentication-status/', { withCredentials: true });
        if (response.status === 200) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
        //console.log(response.status);
        if (response.status == 401 || response.status == 402) {
          setIsAuthenticated(false);
        }
      } catch (error) {
        // console.error('Error fetching authentication status:', error);
        setIsAuthenticated(false);
      }
    };

    fetchAuthenticationStatus();
  }, []);

  return (
    <Router>
      <div className="App">
        <Navbar isAuthenticated={isAuthenticated}/>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route exact path="/teams" element={<CommunityDetailPage/>} />
          <Route exact path="/projects" element={<ProjectsPage/>} />
          <Route exact path="/courses" element={<CoursesPage/>} />
          <Route exact path="/courses/:id" element={<CourseDetailPage/>} />
          <Route exact path="/dashboard" element={<Dashboard isAuthenticated={isAuthenticated}/>} />
          <Route exact path="/courseboard/:id" element={<CourseBoard/>} />
          <Route exact path="/studentBoard" element={<KanbanBoard/>} />
          <Route exact path="/projectBoard/:id" element={<Project/>} />
          <Route exact path="/instructorBoard/:id" element={<InstructorBoard/>} />
          <Route exact path="/communityBoard/:id" element={<CommunityBoard/>} />
          <Route exact path="/welcome/" element={<Welcome/>} />
          <Route exact path="/register" element={<RegisterForm/>} />
          <Route exact path="/login" element={<LoginForm setIsAuthenticated={setIsAuthenticated}/>} />
          {/* Add more routes as needed */}
        </Routes>
        {/* <Sidebar/> */}
        {/* <Home />
        <CommunityDetailPage />
        <CoursesPage /> */}
      </div>
    </Router>
  );
}

export default App;
