import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, Button, CircularProgress } from '@mui/material';
import TextField from '@mui/material/TextField';
import './CourseDetailPage.css';
import api from '../../apis/api';

function CourseDetailPage() {
  const { id } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [enrollmentMessage, setEnrollmentMessage] = useState('');
  const [enrollmentData, setEnrollmentData] = useState({
    name: '',
    email: '',
    contactNo: '',
    hoursDaily: '',
    profession: '',
    experience: ''
  });
  const [selectedContent, setSelectedContent] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEnrollmentData({ ...enrollmentData, [name]: value });
  };

  const handleEnrollNow = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setEnrollmentMessage('');
  };

  const handleJoinCourse = async () => {
    if (enrollmentData.name === '' || enrollmentData.email === '') {
      alert("Name and Email are required fields.");
      return;
    }
    setLoading(true);
    try {
      const enrollmentDataWithCourseId = {
        ...enrollmentData,
        courseId: id
      };

      const response = await api.post('enroll/', enrollmentDataWithCourseId);
      
      if (response.status !== 201) {
        throw new Error('Request Failed. Please reach out to us at +91 7849936058 or email us at info@crozic.com');
      }

      const data =  response.data;
      setEnrollmentMessage(data.message);
      setLoading(false);
      setOpenModal(false); // Close modal after joining course
    } catch (error) {
      console.error('Error enrolling:', error.message);
      setEnrollmentMessage('Request Failed. Please reach out to us at +91 7849936058 or email us at info@crozic.com');
      setLoading(false);
      setOpenModal(false);
    }
  };

  const courses = [
    {
      id: '1',
      title: "Web Development",
      instructor: "Techfine",
      brand: "Crozic",
      description: "Learn the basics of web development including HTML, CSS, and JavaScript.",
      content: [
        { section: "Introduction to HTML", description: "This section covers the basics of HTML including tags, elements, and attributes." },
        { section: "Styling with CSS", description: "In this section, you'll learn how to style HTML elements using CSS properties and selectors." },
        { section: "Introduction to JavaScript", description: "This section introduces you to JavaScript, covering variables, data types, and control flow." },
        { section: "React JS", description: "This section introduces you to JavaScript, covering variables, data types, and control flow." },
      ]
    },
    {
      id: '2',
      title: "Blockchain Basics",
      instructor: "Techfine",
      brand: "Crozic",
      description: "Understand the fundamentals of blockchain technology and its applications.",
      content: [
        { section: "Introduction to Blockchain", description: "Learn what blockchain is and how it works." },
        { section: "Cryptography Basics", description: "Understand the cryptographic principles underlying blockchain technology." },
        { section: "Smart Contracts", description: "Explore the concept of smart contracts and how they are used in blockchain." },
        { section: "Blockchain Applications", description: "Discover various applications of blockchain technology across different industries." },
      ]
    },
    {
      id: '3',
      title: "AI and Machine Learning",
      instructor: "Techfine",
      brand: "Crozic",
      description: "Dive into the world of AI and Machine Learning, learning about key concepts and algorithms.",
      content: [
        { section: "Introduction to AI", description: "Understand what AI is and its different types." },
        { section: "Machine Learning Basics", description: "Learn the basics of machine learning, including supervised and unsupervised learning." },
        { section: "Neural Networks", description: "Discover the structure and function of neural networks." },
        { section: "AI Applications", description: "Explore various applications of AI in different fields." },
      ]
    }
  ];

  const course = courses.find(course => course.id === id);

  const handleContentClick = (index) => {
    setIsVisible(!isVisible);
    setSelectedContent(course.content[index]);
  };

  return (
    <div className="course-detail-page">
      {course ? (
        <>
          <div className="course-header">
            <h1 className="course-title">{course.title}</h1>
            <p className="course-instructor"><strong>Community:</strong> {course.instructor}</p>
            <p className="course-instructor"><strong>Brands Associated:</strong> {course.brand}</p>
            <p className="course-description">{course.description}</p>
            {enrollmentMessage ? (
              <div className="enrollment-message">
                <p>{enrollmentMessage}</p>
              </div>
            ) : (
              <button className="enroll-button" onClick={handleEnrollNow}>Enroll Now</button>
            )}
          </div>
          <div className="course-content">
            <h2 className="content-title">Course Content</h2>
            <ul className="content-list">
              {course.content.map((item, index) => (
                <li key={index} className="content-item">
                  <div className="content-header" onClick={() => handleContentClick(index)}>
                    <span className="section">{item.section}</span>
                    <span className="dropdown-icon">{selectedContent === item ? '-' : '+'}</span>
                  </div>
                  {isVisible && <p className="content-description">{item.description}</p>}
                </li>
              ))}
            </ul>
          </div>
          <div className="course-video">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/XxXyfkrP298"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen>
            </iframe>
          </div>
          <Modal open={openModal} onClose={handleCloseModal}>
            <div className="modal">
              <h2>Customize your {course.title} Course</h2>
              <form>
                <TextField
                  label="Name"
                  name="name"
                  value={enrollmentData.name}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  required
                />
                <TextField
                  label="Email"
                  name="email"
                  value={enrollmentData.email}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  required
                />
                <TextField
                  label="Contact Number"
                  name="contactNo"
                  value={enrollmentData.contactNo}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Hours Daily"
                  name="hoursDaily"
                  value={enrollmentData.hoursDaily}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Profession"
                  name="profession"
                  value={enrollmentData.profession}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Experience"
                  name="experience"
                  value={enrollmentData.experience}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                />
                <div className="modal-actions">
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <Button variant="contained" color="primary" onClick={handleJoinCourse}>
                      Join Course
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </Modal>
        </>
      ) : (
        <p>Course not found</p>
      )}
    </div>
  );
}

export default CourseDetailPage;
