// ProjectsPage.js
import React from 'react';
import './ProjectsPage.css'; // Assuming you have a CSS file for styling
import FITAIC from "../../assets/images/FITAIC.jpeg";
import { Link } from 'react-router-dom';

function ProjectsPage() {
  // Hardcoded data for demonstration
  const projects = [
    { id: 1, title: "Fitaic", description: "AI based workout application to track fitness workout and nutrition", image: "FITAIC.jpeg" },
    { id: 2, title: "Restaurant POS", description: "A restaurant pos application to manage restaurant at ease.", image: "RestaurantPOS.png" },
    { id: 3, title: "Crozbow", description: "An Aroma diffusers D2C brand.", image: "Crozbow.jpeg" },
    { id: 4, title: "Mapnect", description: "A map based social media app.", image: "Mapnect.png" }
  ];

  return (
    <div className="projects-page">
      <h1 className="projects-title">Build Projects that makes an impact</h1>
      <Link to="/projectBoard">
      <div className="projects-list">
        {projects.map(project => (
          <div key={project.id} className="project-card">
            <img src={require(`../../assets/images/${project.image}`)} alt={project.title} className="project-image" />
            <div className="project-info">
              <h3 className="project-title">{project.title}</h3>
              <p className="project-description">{project.description}</p>
            </div>
          </div>
        ))}
      </div>
      </Link>
    </div>
  );
}

export default ProjectsPage;
