import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

const Ticket = ({ ticketData }) => {
  if (!ticketData) return null; // If no ticket data provided, render nothing

  const {
    assignee_id,
    course_id,
    description,
    due_date,
    epic_id,
    learning_video_url,
    mentor_id,
    reference_links,
    sprint_description,
    sprint_end_date,
    sprint_id,
    sprint_name,
    sprint_start_date,
    start_date,
    status,
    team_id,
    ticket_id,
    time_tracked,
    title
  } = ticketData;
  return (
    <TicketContainer>
      <TicketHeader>
        <TicketTitle>{title}</TicketTitle>
        <TicketStatus status={status}>{status}</TicketStatus>
      </TicketHeader>
      <TicketBody>
        <TicketDescription>{description}</TicketDescription>
        <VideoContainer>
        <div className="ticket-video">
          {/* Video component to display course intro */}
          <iframe 
            width="560" 
            height="315" 
            src={learning_video_url}
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen>
          </iframe>
        </div>
      </VideoContainer>
        <TicketInfo>
          <InfoItem>
            <InfoLabel>Mentor:</InfoLabel> Chirag Agrawal
          </InfoItem>
          <InfoItem>
            <InfoLabel>Due Date:</InfoLabel> {due_date}
          </InfoItem>
          <InfoItem>
            <InfoLabel>Time Tracked:</InfoLabel> {time_tracked.hours} hours
          </InfoItem>
          <InfoItem>
            <InfoLabel>Points:</InfoLabel> 5
          </InfoItem>
          <InfoItem>
            <InfoLabel>Related Course Ticket:</InfoLabel> 
            <RelatedTicketLink to="/course-ticket">React.js Course</RelatedTicketLink>
          </InfoItem>
        </TicketInfo>
      </TicketBody>
    </TicketContainer>
  );
};

// Styled Components
const TicketContainer = styled.div`
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

const TicketHeader = styled.div`
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;

const VideoContainer = styled.div`
  margin-top : 20px;
`

const TicketTitle = styled.h1`
  color: #333;
  font-size: 24px;
`;

const TicketStatus = styled.span`
  color: ${({ status }) => (status === 'In Progress' ? '#007bff' : status === 'Done' ? '#28a745' : '#ffc107')};
  font-weight: bold;
`;

const TicketBody = styled.div`
  margin-bottom: 20px;
`;

const TicketDescription = styled.p`
  color: #666;
`;

const TicketInfo = styled.div`
  margin-top: -320px;
  margin-left : 870px;
`;

const InfoItem = styled.div`
  color: #333;
  margin-bottom: 10px;
  margin-top: 20px
`;

const InfoLabel = styled.span`
  font-weight: bold;
`;

const RelatedTicketLink = styled(Link)`
  color: #000000;
  text-decoration: none;
  margin-left: 5px;
`;

const TicketComments = styled.div`
  margin-top: 140px;
`;

const ContentTitle = styled.h3`
  color: #333;
  margin-bottom: 10px;
`;

const Comment = styled.div`
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
`;

const CommentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

const CommentUser = styled.span`
  font-weight: bold;
`;

const CommentDate = styled.span`
  color: #666;
`;

const CommentText = styled.p`
  color: #333;
`;

export default Ticket;
